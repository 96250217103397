<template>
  <div class="locked-card">
    <p>
      The event is currently closed. It will open approximately 5 minutes prior
      to the scheduled start time.
    </p>
    <p>
      In the meantime, we recommend you start your pre-game warm-up routine and
      begin composing a victory speech.
    </p>

    <!-- <p>
      <span v-if="!!gameStartTimeText">
        Please return on:
        <br />{{ gameStartTimeText }}
      </span>
    </p> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import * as moment from "moment"

export default {
  name: "LockedCard",
  props: [],
  data() {
    return {}
  },

  mounted() {
    // check to see if host is not there on a SRE and let in the player if less than 5 minutes.
    console.log("MOUNTED")
    var gameTime = parseInt(this.gameStartTime / 1000)
    var sTime = new Date()
    var currentTime = moment(sTime).unix() + 300 // check for 5 minutes before start time
    const hasHost = this.onlineUsersArray.filter(
      user => user.role === "facilitator"
    )
    console.log("GAME TIME", gameTime)
    console.log("Current Time", currentTime)
    console.log("HAS HOST", hasHost[0])
    if (gameTime < currentTime && !hasHost[0]) {
      console.log("INSIDE")
      this.updateGameAny({
        theKey: this.game.theKey,
        locked: false,
        pickTeamText: "Hang tight, your host will arrive shortly!"
      })
    }
  },
  computed: {
    ...mapGetters(["user", "game"]),
    ...mapGetters(["onlineUsersArray"]),
    // gameStartTimeText() {
    //   return this.gameStartTimeBackupText
    //     ? this.gameStartTimeBackupText
    //     : this.gameStartTimeFormatted
    // },
    gameStartTimeFormatted() {
      if (!this.gameStartTime) return null
      const text = moment(this.gameStartTime).format("MM/DD/YYYY hh:mm")
      return this.timeZone ? `${text} ${this.timeZone}` : text
    },
    gameStartTimeBackupText() {
      return this.game ? this.game.date : null
    },
    gameStartTime() {
      // if (this.game.startTimestamp) console.log("THE STAERT TIME STAMP", this.game.startTimestamp)
      return this.game && this.user.gameID ? this.game.startTimestamp : 0
    }
  },

  created() {
    try {
      this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (e) {
      console.warn(e)
    }
  },

  methods: {
    ...mapActions("Games", ["updateGameAny"])
  }
}
</script>

<style lang="scss">
.locked-card {
  p {
    margin-top: 30px;
    font-size: 22px;
    line-height: 28px;
    color: $secondary-card-text;
    font-weight: 700;
  }
}
</style>
