var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "locked-card" }, [
      _c("p", [
        _vm._v(
          " The event is currently closed. It will open approximately 5 minutes prior to the scheduled start time. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " In the meantime, we recommend you start your pre-game warm-up routine and begin composing a victory speech. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }